import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

import { toastify } from '@src/Components/Toast';

const NOT_IN_THE_FORMAT_MESSAGE =
  '엑셀 양식에 맞지않는 파일을 업로드하셨습니다. 엑셀파일을 확인해주세요.';

/**
 * - 과거, 엑셀의 로우가 상당히 많은 상태로 서버에 전달하는 과정에서 서버가 죽는 문제가 발생했음. (로우 만개정도를 계산하느라.)
 * - 계산의 책임을 프론트엔드가 지는 방향이 맞다고 결론내림. 따라서, 엑셀을 json형태로 만들어서 서버에 우리가 전달해주자.
 * - 이런 취지에서 만든 훅이 useExcelConversionArray
 *
 * @param excelObjectFormat - 객체배열형태로 변환될 엑셀의 객체형태를 넣어주세요.
 * @param excelHeaderFormat - 엑셀의 헤더와 매핑될 키값이 정의된 객체를 넣어주세요.
 * @param dateTypeHeader - 날짜타입에 해당하는 헤더명들을 넣어주세요.
 * @returns convertExcelToArray - 파일과 콜백함수를 받는 배열변환 함수.
 */
export function useExcelConversionArray<T extends Record<string, any>>({
  excelObjectFormat,
  excelHeaderFormat,
  dateTypeHeader,
}: {
  excelObjectFormat: T;
  excelHeaderFormat: Record<string, string>;
  dateTypeHeader?: readonly string[];
}) {
  type ExcelHeaderFormatKey = keyof typeof excelHeaderFormat;

  /**
   *
   * @param file 변환을 원하는 엑셀 파일. ex) file[0]
   * @param convertExcelCallback 변환한 엑셀 배열에 대해 실행하고싶은 콜백함수.
   */
  const convertExcelToArray = (file: Blob, convertExcelCallback: (result: T[]) => void) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const data = reader.result;
      const workbook = XLSX.read(data, {
        type: 'binary',
        cellDates: true,
        dateNF: 'yyyy-mm-dd',
      });

      workbook.SheetNames.forEach((sheetName) => {
        try {
          const result = XLSX.utils
            .sheet_to_json<Record<string, string | number>>(workbook.Sheets[sheetName])
            ?.map((cell) =>
              Object.entries(cell).reduce((acc, [key, value]) => {
                if (dateTypeHeader?.includes(key)) {
                  return {
                    ...acc,
                    [excelHeaderFormat[key as ExcelHeaderFormatKey]]:
                      typeof value === 'object'
                        ? dayjs(new Date(value)).format('YYYY-MM-DD')
                        : String(value),
                  };
                }
                if (excelHeaderFormat[key as ExcelHeaderFormatKey]) {
                  return {
                    ...acc,
                    [excelHeaderFormat[key as ExcelHeaderFormatKey]]: String(value),
                  };
                }
                throw new Error(NOT_IN_THE_FORMAT_MESSAGE);
              }, excelObjectFormat)
            ) as unknown as T[];
          convertExcelCallback(result);
        } catch (error) {
          toastify(String(error), { type: 'error' });
        }
      });
    };
    reader.readAsBinaryString(file);
  };

  return {
    convertExcelToArray,
  };
}
