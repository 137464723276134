import styled, { css } from 'styled-components';

import Flex from '@src/Components/Flex';

export const ChangePasswordInput = styled.input`
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  ${({ theme: { colors, radiuses, sizes } }) => {
    const { gray, blue, red } = colors;
    return css`
      border: 0.1rem solid ${gray['400']};
      border-radius: ${radiuses['0.6']};
      color: ${gray['800']};
      font-size: ${sizes['1.2']};

      &:focus {
        border: 0.1rem solid ${blue[500]};
        box-shadow: 0 0 0.5rem rgba(0, 112, 243, 0.5);
      }

      &.error {
        border: 0.1rem solid ${red[500]};
        box-shadow: 0 0 0.5rem rgba(238, 0, 0, 0.5);
      }
    `;
  }}
`;

export const ErrorText = styled.h4`
  color: ${({ theme }) => theme.colors.red['400']};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeights['400']};
  height: 1.3rem;
`;

export const Wrapper = styled(Flex)`
  height: 100%;
  flex-direction: column;
`;

export const CustomModalFooter = styled(Flex)`
  margin-top: 0rem;
  padding: ${({ theme }) => theme.spaces['0.8']};
  background: ${({ theme }) => theme.colors.gray[200]};
`;
