import { useRef, useState } from 'react';

import DataSheetGrid from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { toastify } from '@Components/Toast';
import { Text } from '@Components/Typography';
import { useShelfLifeExcelCheckMutation } from '@Features/ShelfLifeStock/apis/useShelfLifeExcelCheckMutation';
import { useShelfLifeExcelStockMutation } from '@Features/ShelfLifeStock/apis/useShelfLifeExcelStockMutation';
import {
  CAUTION_TIP_TEXT_ARRAY,
  DATE_FORMAT_REGEX,
  EXCEL_DATA_FORMAT,
  INVALID_CENTER_ID,
  INVALID_DATE_OF_RECORD,
  REQUIRED_CENTER_CODE,
  REQUIRED_DATE_OF_RECORD,
  SHELF_LIFE_STOCK_ADD_COLUMNS_KEY,
  SHELF_LIFE_STOCK_ADD_GRID_OPTIONS,
  SHELF_LIFE_STOCK_DATE_HEADER,
  SHELF_LIFE_STOCK_EXCEL_SAMPLE_NAME,
  SHELF_LIFE_SUCCESS_SERVER_MESSAGE,
  STANDARD_CENTER_ID,
  STANDARD_CURRENT_DATE,
} from '@Features/ShelfLifeStock/components/ShelfLifeStockAddModal/constant';
import { ErrorCell } from '@Features/ShelfLifeStock/components/ShelfLifeStockAddModal/ShelfLifeStockAddModal.style';
import { useDialog, useFormField } from '@Hooks';
import ModalFooter from '@Layout/components/ModalFooter';
import ResultHeader from '@Layout/components/ResultHeader';
import { useCenterCodeList } from '@src/Apis/useCenterCodeList';
import CautionBox from '@src/Components/CautionBox';
import { DatePickerLabel } from '@src/Components/Date/DatePicker';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import CenterCodeModal from '@src/Components/Modals/CenterCodeModal';
import ExcelFileUpload from '@src/Features/InboundHistory/components/InboundHistoryNewModal/ExcelFileUpload';
import type {
  ShelfLifeStockCheckedListElement,
  ShelfLifeStockExcelRow,
} from '@src/Features/ShelfLifeStock/components/ShelfLifeStockAddModal/ShelfLifeStockAddModal.type';
import { useExcelConversionArray } from '@src/Hooks/useExcelConversionArray';
import { performIfConfirmed } from '@Utils/helper';

interface ShelfLifeStockAddModalProps {
  searchAboutValueSetInModal: (dateOfRecord: string, centerCode: string, centerId: string) => void;
}

function ShelfLifeStockAddModal({ searchAboutValueSetInModal }: ShelfLifeStockAddModalProps) {
  const { columns } = SHELF_LIFE_STOCK_ADD_GRID_OPTIONS;
  const { form, attributes, setForm } = useFormField({
    centerId: STANDARD_CENTER_ID,
    centerCode: '',
    dateOfRecord: STANDARD_CURRENT_DATE,
  });

  const { convertExcelToArray } = useExcelConversionArray({
    excelObjectFormat: EXCEL_DATA_FORMAT,
    excelHeaderFormat: SHELF_LIFE_STOCK_ADD_COLUMNS_KEY,
    dateTypeHeader: SHELF_LIFE_STOCK_DATE_HEADER,
  });

  const [shelfLifeStockList, setShelfLifeStockList] = useState<ShelfLifeStockCheckedListElement[]>(
    []
  );
  const convertedExcelData = useRef<ShelfLifeStockExcelRow[]>([]);
  const [excelValidateChecked, setExcelValidateChecked] = useState(false);

  const { resetDialog } = useDialog('ShelfLifeStockAddModal');
  const { openDialog: openCenterCodeDialog } = useDialog('CenterCodeModal');
  const { saveShelfLifeExcelStock } = useShelfLifeExcelStockMutation({
    refetch: () => searchAboutValueSetInModal(form.dateOfRecord, form.centerCode, form.centerId),
  });
  const { checkShelfLifeExcel } = useShelfLifeExcelCheckMutation({
    successCallback: (data) => setShelfLifeStockList(data),
  });
  const { data: centerCodeList } = useCenterCodeList();

  const fileUploadCheck = (result: ShelfLifeStockExcelRow[]) => {
    convertedExcelData.current = result;
    checkShelfLifeExcel({
      items: result,
    });
    setExcelValidateChecked(true);
  };

  const onFileUpload = (file: FileList) => {
    convertExcelToArray(file[0], fileUploadCheck);
  };

  const openCenterCode = () => {
    openCenterCodeDialog({
      title: '코드 선택',
      content: (
        <CenterCodeModal
          selectCenterCode={({ centerCode }) => {
            if (!centerCode) return;
            const centerId = String(
              centerCodeList?.find((item) => item.centerCode === centerCode)?.id || ''
            );
            setForm((prev) => ({
              ...prev,
              centerCode,
              centerId,
            }));
          }}
        />
      ),
      size: 'medium',
    });
  };

  const renderCheckMessage = ({ rowData }: { rowData: ShelfLifeStockCheckedListElement }) => {
    return (
      <ErrorCell bg={rowData.errorMessage}>
        <Text color='gray.900' fontWeight={400} size={1.2}>
          {rowData.errorMessage}
        </Text>
      </ErrorCell>
    );
  };

  const onClickSubmit = () => {
    for (const row of shelfLifeStockList) {
      if (row.errorMessage !== SHELF_LIFE_SUCCESS_SERVER_MESSAGE) {
        toastify(row.errorMessage, { type: 'error' });
        return false;
      }
    }

    if (typeof form.dateOfRecord !== 'string' || !form.dateOfRecord) {
      return toastify(REQUIRED_DATE_OF_RECORD, { type: 'error' });
    }

    if (!DATE_FORMAT_REGEX.test(form.dateOfRecord)) {
      return toastify(INVALID_DATE_OF_RECORD, { type: 'error' });
    }

    if (!form.centerCode) {
      return toastify(REQUIRED_CENTER_CODE, { type: 'error' });
    }

    const parsedCenterId = parseInt(form.centerId, 10);
    if (typeof parsedCenterId !== 'number' || Number.isNaN(parsedCenterId))
      return toastify(INVALID_CENTER_ID, {
        type: 'error',
      });

    performIfConfirmed('저장하시겠습니까?', () => {
      saveShelfLifeExcelStock({
        centerId: parsedCenterId,
        dateOfRecord: form.dateOfRecord,
        items: convertedExcelData.current,
      });
    });
  };

  return (
    <Flex direction='column' css='height:100%'>
      <Flex direction='column' rowGap={16} css='flex: 1'>
        <ResultHeader title='재고 업로드' total={shelfLifeStockList.length} />
        <Flex gap={50}>
          <InputButtonLabel
            {...attributes.centerCode}
            label='센터코드'
            buttonText='검색'
            readOnly
            buttonClick={openCenterCode}
          />
          <DatePickerLabel
            selected={form.dateOfRecord}
            maxDate={new Date(STANDARD_CURRENT_DATE)}
            label='기준날짜'
            {...attributes.dateOfRecord}
          />
        </Flex>
        <ExcelFileUpload
          onFileUpload={onFileUpload}
          fileName={SHELF_LIFE_STOCK_EXCEL_SAMPLE_NAME}
        />
        <CautionBox title='참고사항' messages={CAUTION_TIP_TEXT_ARRAY} />
        <DataSheetGrid
          columns={columns}
          value={shelfLifeStockList}
          customRendererList={[{ id: 'errorMessage', component: renderCheckMessage }]}
        />
      </Flex>
      <ModalFooter>
        <Button disabled={!excelValidateChecked} onClick={onClickSubmit}>
          저장
        </Button>
        <Button onClick={resetDialog}>취소</Button>
      </ModalFooter>
    </Flex>
  );
}

export default ShelfLifeStockAddModal;
