import dayjs from 'dayjs';
import { keyColumn, textColumn } from 'react-datasheet-grid';

export const INITIAL_CLAIMS_LIST_FILTER = {
  startRequestDay: dayjs().subtract(30, 'd').format('YYYY-MM-DD'), // 반품접수 시작일자
  endRequestDay: dayjs().format('YYYY-MM-DD'), // 반품접수 끝일자
  startCompleteDay: '', // 반품완료 시작일자
  endCompleteDay: '', // 반품완료 끝일자
  category: '', // 접수유형
  status: '', // 반품 상태코드
  reasonType: '', // 반품사유
  externalIds: '', // 반품번호목록
  wokeys: '', // 출고번호목록
  wikeys: '', // 입고번호목록
  orderId: '', // 주문번호
  reverseCodes: '', // 반품번호
  centerId: '', // 센터코드의 ID
  centerCode: '' // 센터코드
};

export const CLAIM_STATUS_CODE_COLOR = {
  반품예정: 'orange.500',
  반품완료: 'green.500',
  전송완료: 'blue.500',
  반품취소: 'red.500',
};

export const CLAIMS_CATEGORY_OPTIONS = [
  { label: '전체', value: '' },
  { label: '교환', value: 'APP_EXCHANGE' },
  { label: '반품', value: 'APP_RETURN' },
];

export const CLAIMS_STATUS_OPTIONS = [
  { label: '전체', value: '' },
  { label: '반품예정', value: 'DRAFT' },
  { label: '전송완료', value: 'SENT' },
  { label: '반품완료', value: 'COMPLETED' },
  { label: '반품취소', value: 'CANCELED' },
];

export const CLAIMS_REASON_TYPE_OPTIONS = [
  { label: '전체', value: '' },
  { label: '단순변심', value: 'CHANGE_MIND' },
  { label: '상품불량', value: 'PRODUCT_DEFECT' },
  { label: '상품파손', value: 'PRODUCT_DAMAGE' },
  { label: '오피킹', value: 'PICKING_WRONG' },
  { label: '오배송', value: 'DELIVERY_WRONG' },
  { label: '배송&출고 지연', value: 'DELIVERY_DELAY' },
  { label: '품절', value: 'OUT_OF_STOCK' },
  { label: '기타(서비스 불만족)', value: 'ETC' },
  { label: '업체배송', value: 'VENDOR' },
];

export const CLAIMS_LIST_COLUMNS = [
  {
    ...keyColumn('reverseCode', textColumn),
    title: '반품번호',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('externalId', textColumn),
    title: '접수번호',
    disabled: true,
    minWidth: 100,
  },
  {
    ...keyColumn('category', textColumn),
    title: '접수유형',
    disabled: true,
  },
  {
    ...keyColumn('status', textColumn),
    title: '상태',
    disabled: true,
  },
  {
    ...keyColumn('reasonType', textColumn),
    title: '사유',
    disabled: true,
  },
  {
    ...keyColumn('requestDay', textColumn),
    title: '접수일',
    disabled: true,
  },
  {
    ...keyColumn('completeDay', textColumn),
    title: '완료일',
    disabled: true,
  },
  {
    ...keyColumn('wikey', textColumn),
    title: '입고번호',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('wokey', textColumn),
    title: '출고번호',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('outboundStatus', textColumn),
    title: '출고상태',
    disabled: true,
  },
  {
    ...keyColumn('orderId', textColumn),
    title: '원주문번호',
    disabled: true,
  },
  {
    ...keyColumn('deliveryType', textColumn),
    title: '배송유형',
    disabled: true,
  },
  {
    ...keyColumn('centerCode', textColumn),
    title: '센터코드',
    minWidth: 120,
    maxWidth: 120,
  },
  {
    ...keyColumn('centerName', textColumn),
    title: '센터명',
    disabled: true,
    minWidth: 150,
  },
  {
    ...keyColumn('createdAt', textColumn),
    title: '등록일시',
    disabled: true,
    minWidth: 120,
  },
  {
    ...keyColumn('updatedAt', textColumn),
    title: '최근 업데이트',
    disabled: true,
    minWidth: 120,
  },
];

export const CLAIM_INBOUND_ITEM_COLUMNS = [
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    disabled: true,
  },
  {
    ...keyColumn('availableQuantity', textColumn),
    title: '반품예정수량',
    disabled: true,
  },
  {
    ...keyColumn('acceptedQuantity', textColumn),
    title: '양품화수량',
    disabled: true,
  },
  {
    ...keyColumn('defectiveQuantity', textColumn),
    title: '폐기수량',
    disabled: true,
  },
  {
    ...keyColumn('parcel', textColumn),
    title: '운송사',
    disabled: true,
  },
  {
    ...keyColumn('invoiceNumber', textColumn),
    title: '반송장번호',
    disabled: true,
  },
  {
    ...keyColumn('updatedAt', textColumn),
    title: '최근 업데이트',
    disabled: true,
  },
];

export const CLAIM_OUTBOUND_ITEM_COLUMNS = [
  {
    ...keyColumn('productItemId', textColumn),
    title: '품목코드',
    disabled: true,
  },
  {
    ...keyColumn('productItemName', textColumn),
    title: '품목명',
    disabled: true,
  },
  {
    ...keyColumn('availableQuantity', textColumn),
    title: '출고예정수량',
    disabled: true,
  },
  {
    ...keyColumn('receivedQuantity', textColumn),
    title: '출고실적수량',
    disabled: true,
  },
  {
    ...keyColumn('parcel', textColumn),
    title: '운송사',
    disabled: true,
  },
  {
    ...keyColumn('invoiceNumber', textColumn),
    title: '송장번호',
    disabled: true,
  },
  {
    ...keyColumn('updatedAt', textColumn),
    title: '최근 업데이트',
    disabled: true,
  },
];

export const REQUIRED_CENTER_CODE = '센터코드를 입력해주세요.';
