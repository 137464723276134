import { useEffect, useRef } from 'react';

export const useCrossBrowser = () => {
  const isWindowOs = useRef(false);
  useEffect(() => {
    if (navigator.userAgent.indexOf('Windows') !== -1) {
      document.documentElement.classList.add('windows-os');
      isWindowOs.current = true;
    }
  }, []);

  return {
    isWindowOs,
  };
};
