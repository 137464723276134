import styled from 'styled-components';

import { flexRow } from '@Styles/utils/flex';
import type { Nullable } from '@Utils/type';

import { SHELF_LIFE_SUCCESS_SERVER_MESSAGE } from './constant';

type ErrorCellProps = { bg: Nullable<string> };

export const ErrorCell = styled.div<ErrorCellProps>`
  background-color: ${({ bg }) => {
    if (!bg) return '#fff';
    if (bg === SHELF_LIFE_SUCCESS_SERVER_MESSAGE) return `#DBFFE5`;
    return '#FFABAB';
  }};
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  ${flexRow('normal', 'center')}
  flex-wrap: nowrap;

  & > p {
    white-space: nowrap;
  }
`;
