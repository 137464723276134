import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';

import Form from '@Components/Forms/Form';
import { LoginImage } from '@Images';
import { EMAIL_REGEX, EMPTY_STRING_REGEX } from '@src/Constants/regex';
import { useMutation } from '@tanstack/react-query';

import { postLogin } from '../../apis';

import {
  Badge,
  Description,
  ErrorText,
  LoginFormField,
  LoginInput,
  PetFButton,
  Title,
} from './LoginForm.style';

function LoginForm() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    loginId: '',
    password: '',
  });
  const [error, setError] = useState('');
  const { mutate: setLogin } = useMutation(postLogin, {
    onSuccess: (data) => {
      if (!data) return;
      const { accessToken, refreshToken } = data;
      try {
        Cookies.set('wms_tk', accessToken);
        localStorage.setItem('wms_rftk', refreshToken);
        navigate('/', { replace: true });
      } catch (err) {
        alert('로그인 실패염, 네트워크 확인해주세욤');
        console.error(err);
      }
    },
  });

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const removeEmptyStringValue = value.replace(EMPTY_STRING_REGEX, '');
    setForm((prev) => ({
      ...prev,
      [name]: removeEmptyStringValue,
    }));
    error && setError('');
  };

  const login = () => {
    if (!form.loginId || !form.password) {
      setError('필수값을 입력해주세요.');
      return;
    }

    if (!EMAIL_REGEX.test(form.loginId)) {
      setError('올바른 이메일 형식이 아닙니다.');
      return;
    }

    setLogin(form);
  };

  return (
    <LoginFormField direction='column' align='center'>
      <Title>
        <Badge src={LoginImage.loginLogoBadge} alt='login logo badge' />
        펫프렌즈 WMS 로그인
      </Title>
      <Description>
        펫프렌즈 센터, 재고 관리 업무를 도와드려요!
        <br /> 궁금한 사항은 PO팀에 문의해 주세요
      </Description>
      <Form>
        <LoginInput
          className={error ? 'error' : ''}
          placeholder='이메일을 입력해주세요'
          name='loginId'
          value={form.loginId}
          onChange={onChangeHandler}
        />
        <LoginInput
          className={error ? 'error' : ''}
          placeholder='비밀번호를 입력해주세요'
          name='password'
          type='password'
          value={form.password}
          onChange={onChangeHandler}
        />
        <ErrorText>{error}</ErrorText>
        <PetFButton onClick={login}>로그인</PetFButton>
      </Form>
    </LoginFormField>
  );
}

export default LoginForm;
