import { EXCEL_CHECK_ERROR_MESSAGE } from '@Features/ShelfLifeStock/components/ShelfLifeStockAddModal/constant';
import { client } from '@src/client';
import { toastify } from '@src/Components/Toast';
import { useFullScreenActivityIndicator } from '@src/Hooks';
import { useMutation } from '@tanstack/react-query';

import type { ShelfLifeStockCheckedListElement } from '../components/ShelfLifeStockAddModal/ShelfLifeStockAddModal.type';

import type { PostShelfLifeExcelCheckBody } from './type';

interface UseShelfLifeExcelCheckMutationProps {
  successCallback: (data: ShelfLifeStockCheckedListElement[]) => void;
}

const postShelfLifeExcelCheck = async (body: PostShelfLifeExcelCheckBody) => {
  const { data } = await client.post<{ items: ShelfLifeStockCheckedListElement[] }>(
    '/shelf-life/stocks/check',
    body
  );
  return data;
};
/**
 * 유통기한별 재고 엑셀등록시 엑셀데이터 유효성 검사 훅
 */
export const useShelfLifeExcelCheckMutation = ({
  successCallback,
}: UseShelfLifeExcelCheckMutationProps) => {
  const { showActivityIndicator, hideActivityIndicator } = useFullScreenActivityIndicator();

  const { mutate: checkShelfLifeExcel } = useMutation(postShelfLifeExcelCheck, {
    onMutate: () => showActivityIndicator(),
    onSuccess: (data) => successCallback(data.items),
    onError: () => {
      toastify(EXCEL_CHECK_ERROR_MESSAGE, { type: 'error' });
    },
    onSettled: () => hideActivityIndicator(),
  });

  return { checkShelfLifeExcel };
};
