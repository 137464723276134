import { toastify } from '@src/Components/Toast';

import { type GetShelfLifeStocksListParams } from './apis/useShelfLifeList';

type MessageInfo = Partial<Record<keyof GetShelfLifeStocksListParams, string>>;

export const validateSearch =
  ({
    filter,
    callback,
    message,
  }: {
    filter: GetShelfLifeStocksListParams;
    callback: VoidFunction;
    message: MessageInfo;
  }) =>
  () => {
    if (!filter.dateOfRecord) {
      return toastify(message.dateOfRecord, { type: 'error' });
    }
    if (!filter.centerId) {
      return toastify(message.centerId, { type: 'error' });
    }
    callback();
  };
