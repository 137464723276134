import { toastify } from '@src/Components/Toast';

import { type GetClaimInquireListParams } from './apis/useClaimInquireList';

type MessageInfo = Partial<Record<keyof GetClaimInquireListParams, string>>;

export const validateSearch =
  ({
    filter,
    callback,
    message,
  }: {
    filter: GetClaimInquireListParams;
    callback: VoidFunction;
    message: MessageInfo;
  }) =>
  () => {
    if (
      (!filter.startCompleteDay || !filter.endCompleteDay) &&
      (!filter.startRequestDay || !filter.endRequestDay)
    ) {
      return toastify(message.startRequestDay, { type: 'error' });
    }
    if (!filter.centerId) {
      return toastify(message.centerId, { type: 'error' });
    }
    callback();
  };
