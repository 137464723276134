import { ChangeEvent, useState } from 'react';

import Flex from '@src/Components/Flex';
import Button from '@src/Components/Forms/Button';
import Form from '@src/Components/Forms/Form';
import { WithLabel } from '@src/Components/Forms/WithLabel';
import {
  ChangePasswordInput,
  CustomModalFooter,
  ErrorText,
  Wrapper,
} from '@src/Components/Modals/ChangePasswordModal/ChangePasswordModal.style';
import type { ChangePasswordData } from '@src/Components/Modals/ChangePasswordModal/ChangePasswordModal.type';
import { toastify } from '@src/Components/Toast';
import { EMPTY_STRING_REGEX, PASSWORD_REGEX } from '@src/Constants/regex';
import { useDialog } from '@src/Hooks';
import FormField from '@src/Layout/components/FormField';
import { performIfConfirmed } from '@src/Utils/helper';
import { useMutation } from '@tanstack/react-query';

import { putChangePassword } from './apis';
import {
  INITIAL_PASSWORD_CHANGE_FORM_STATE,
  NOT_SAME_ERROR,
  PASSWORD_CHANGE_CONFIRM,
  PASSWORD_CHANGE_SUCCESS_MESSAGE,
  REQUIRED_ERROR,
  SAME_CURRENT_PASSWORD_ERROR,
  VALIDATION_ERROR,
} from './constant';

function ChangePasswordModal() {
  const { resetDialog } = useDialog('ChangePasswordModal');

  const { mutate: setChangePassword } = useMutation(putChangePassword, {
    onSuccess: () => {
      toastify(PASSWORD_CHANGE_SUCCESS_MESSAGE);
      resetDialog();
    },
  });
  const [form, setForm] = useState<ChangePasswordData>(INITIAL_PASSWORD_CHANGE_FORM_STATE);

  const [error, setError] = useState<ChangePasswordData>(INITIAL_PASSWORD_CHANGE_FORM_STATE);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const removeEmptyStringValue = value.replace(EMPTY_STRING_REGEX, '');
    setForm((prev) => ({
      ...prev,
      [name]: removeEmptyStringValue,
    }));
    error[name as keyof ChangePasswordData] &&
      setError((prev) => ({
        ...prev,
        [name]: '',
      }));
  };

  const onSubmitChangePassword = () => {
    if (!form.currentPassword || !form.newPassword || !form.newPasswordConfirm) {
      return setError({
        currentPassword: form.currentPassword ? '' : REQUIRED_ERROR,
        newPassword: form.newPassword ? '' : REQUIRED_ERROR,
        newPasswordConfirm: form.newPasswordConfirm ? '' : REQUIRED_ERROR,
      });
    }
    if (
      !PASSWORD_REGEX.test(form.currentPassword) ||
      !PASSWORD_REGEX.test(form.newPassword) ||
      !PASSWORD_REGEX.test(form.newPasswordConfirm)
    ) {
      return setError({
        currentPassword: PASSWORD_REGEX.test(form.currentPassword) ? '' : VALIDATION_ERROR,
        newPassword: PASSWORD_REGEX.test(form.newPassword) ? '' : VALIDATION_ERROR,
        newPasswordConfirm: PASSWORD_REGEX.test(form.newPasswordConfirm) ? '' : VALIDATION_ERROR,
      });
    }
    if (form.newPassword !== form.newPasswordConfirm) {
      return setError((prev) => ({
        ...prev,
        newPasswordConfirm: NOT_SAME_ERROR,
      }));
    }
    if (form.currentPassword === form.newPassword) {
      return setError((prev) => ({
        ...prev,
        newPassword: SAME_CURRENT_PASSWORD_ERROR,
      }));
    }
    performIfConfirmed(PASSWORD_CHANGE_CONFIRM, () => {
      setChangePassword({ currentPassword: form.currentPassword, newPassword: form.newPassword });
    });
  };

  return (
    <Wrapper>
      <Form onSubmit={onSubmitChangePassword}>
        <FormField css={{ height: '100%' }}>
          <Flex direction='column' gap={10} css={{ width: '100%' }}>
            <WithLabel label='이전 비밀번호'>
              <Flex direction='column' gap={10} css={{ width: '100%' }}>
                <ChangePasswordInput
                  type='password'
                  value={form.currentPassword}
                  onChange={onChangeHandler}
                  name='currentPassword'
                  placeholder='비밀번호를 입력해주세요'
                />
                <ErrorText>{error.currentPassword}</ErrorText>
              </Flex>
            </WithLabel>
            <WithLabel label='새 비밀번호'>
              <Flex direction='column' gap={10} css={{ width: '100%' }}>
                <ChangePasswordInput
                  type='password'
                  value={form.newPassword}
                  onChange={onChangeHandler}
                  name='newPassword'
                  placeholder='영문/숫자/특수문자/혼합 8~20자'
                />
                <ErrorText>{error.newPassword}</ErrorText>
              </Flex>
            </WithLabel>
            <WithLabel label='새 비밀번호 확인'>
              <Flex direction='column' gap={10} css={{ width: '100%' }}>
                <ChangePasswordInput
                  type='password'
                  value={form.newPasswordConfirm}
                  onChange={onChangeHandler}
                  name='newPasswordConfirm'
                  placeholder='영문/숫자/특수문자/혼합 8~20자'
                />
                <ErrorText>{error.newPasswordConfirm}</ErrorText>
              </Flex>
            </WithLabel>
          </Flex>
        </FormField>
        <CustomModalFooter align='center' justify='flex-end' colGap={16}>
          <Button onClick={resetDialog} isPermissionRequired={false}>
            취소
          </Button>
          <Button type='submit' isPermissionRequired={false}>
            변경
          </Button>
        </CustomModalFooter>
      </Form>
    </Wrapper>
  );
}

export default ChangePasswordModal;
