import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import Cookies from 'js-cookie';

import { FullScreenActivityIndicatorContainer } from '@Components/ActivityIndicator';
import DialogContainer from '@Components/Dialog';
import Tab from '@Components/Tab';
import Login from '@Features/Login';

import { useCrossBrowser } from './Hooks/useCrossBrowser';
import Header from './Layout/components/Header';
import { Container } from './Layout/Layout.style';
import Layout from './Layout';

function PrivateRoute() {
  const userToken = Cookies.get('wms_tk');
  if (!userToken) return <Navigate to='/login' replace />;
  return <Outlet />;
}

function MainPage() {
  return (
    <Container>
      <Header />
      <Tab />
      <Layout />
    </Container>
  );
}

function App() {
  useCrossBrowser();

  return (
    <>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path='/' element={<MainPage />} />
        </Route>
        <Route path='/login' element={<Login />} />
      </Routes>
      <DialogContainer />
      <FullScreenActivityIndicatorContainer />
    </>
  );
}

export default App;
