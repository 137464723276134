import DataSheetGrid from '@src/Components/DataSheetGrid';
import Button from '@src/Components/Forms/Button';
import ExcelHistoryModal from '@src/Components/Modals/ExcelHistoryModal/ExcelHistoryModal';
import { toastify } from '@src/Components/Toast';
import { CUSTOM_GUTTER_COLUMN } from '@src/Constants/grid';
import { useDialog, useFormField, useFullScreenActivityIndicator } from '@src/Hooks';
import FilterHeader from '@src/Layout/components/FilterHeader';
import GridLayout from '@src/Layout/components/GridLayout';
import ResultHeader from '@src/Layout/components/ResultHeader';

import { useDownloadShelfLifeListMutation } from './apis/useShelfLifeExceldownload';
import { useShelfLifeStocksList } from './apis/useShelfLifeList';
import ShelfLifeFilter from './components/ShelfLifeFilter';
import ShelfLifeStockAddModal from './components/ShelfLifeStockAddModal/ShelfLifeStockAddModal';
import {
  INITIAL_SHELF_LIFE_LIST_FILTER,
  REQUIRED_CENTER_CODE,
  REQUIRED_DATE_OF_RECORD,
  SHELF_LIFE_LIST_COLUMNS,
} from './constant';
import { validateSearch } from './util';

function ShelfLifeStock() {
  const { setSearchFilter, shelfLifeStocksList, shelfLifeStocksListCount } =
    useShelfLifeStocksList();
  const { openDialog: openShelfLifeStockAddModal } = useDialog('ShelfLifeStockAddModal');
  const { showActivityIndicator } = useFullScreenActivityIndicator();
  const { excelDownload } = useDownloadShelfLifeListMutation();

  const formField = useFormField(INITIAL_SHELF_LIFE_LIST_FILTER);

  const shelfLifeStocksExcelHistory = useDialog('ExcelHistory');

  const onClickSearch = validateSearch({
    filter: { ...formField.form },
    callback: () => {
      showActivityIndicator();
      setSearchFilter({ ...formField.form });
    },
    message: {
      dateOfRecord: REQUIRED_DATE_OF_RECORD,
      centerId: REQUIRED_CENTER_CODE,
    },
  });

  const onClickExcelDownload = () => {
    if (!shelfLifeStocksList?.length) {
      toastify('유통기한별 재고조회 리스트 데이터가 없습니다.', { type: 'error' });
      return false;
    }

    validateSearch({
      filter: { ...formField.form },
      callback: () => {
        showActivityIndicator();
        excelDownload({ ...formField.form });
      },
      message: {
        dateOfRecord: REQUIRED_DATE_OF_RECORD,
        centerId: REQUIRED_CENTER_CODE,
      },
    })();
  };

  const searchAboutValueSetInModal = (
    dateOfRecord: string,
    centerCode: string,
    centerId: string
  ) => {
    setSearchFilter({
      ...formField.form,
      dateOfRecord,
      centerCode,
      centerId,
    });
    formField.setForm({
      ...formField.form,
      dateOfRecord,
      centerCode,
      centerId,
    });
  };

  const openDownLoadHistoryModalHandler = () => {
    shelfLifeStocksExcelHistory.openDialog({
      title: '엑셀 다운로드 내역',
      content: <ExcelHistoryModal targetUrl='shelf_life' />,
    });
  };

  const openExcelUploadModal = () => {
    openShelfLifeStockAddModal({
      title: '엑셀 업로드',
      content: <ShelfLifeStockAddModal searchAboutValueSetInModal={searchAboutValueSetInModal} />,
      size: 'medium',
    });
  };

  return (
    <div>
      <FilterHeader>
        <Button onClick={onClickSearch} isPermissionRequired={false}>
          조회
        </Button>
        <Button onClick={openExcelUploadModal}>엑셀 업로드</Button>
        <Button isPermissionRequired={false} onClick={onClickExcelDownload}>
          엑셀
        </Button>
        <Button isPermissionRequired={false} onClick={openDownLoadHistoryModalHandler}>
          엑셀 다운로드 요청 내역
        </Button>
      </FilterHeader>
      <ShelfLifeFilter formField={formField} />
      <GridLayout
        header={<ResultHeader title='재고조회' total={shelfLifeStocksListCount} />}
        grid={
          <DataSheetGrid
            columns={SHELF_LIFE_LIST_COLUMNS}
            value={shelfLifeStocksList}
            gutterColumn={CUSTOM_GUTTER_COLUMN}
          />
        }
      />
    </div>
  );
}

export default ShelfLifeStock;
