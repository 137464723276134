export const REQUIRED_ERROR = '비밀번호를 입력해주세요.';

export const VALIDATION_ERROR = '영문/숫자/특수문자/혼합 8~20자로 입력해주세요.';

export const NOT_SAME_ERROR = '비밀번호가 일치하지 않습니다.';

export const SAME_CURRENT_PASSWORD_ERROR =
  '현재 비밀번호와 동일합니다. 다른 비밀번호로 작성해주세요.';

export const PASSWORD_CHANGE_SUCCESS_MESSAGE = '비밀번호가 정상적으로 변경됐습니다.';

export const PASSWORD_CHANGE_CONFIRM = '비밀번호를 변경하시겠습니까?';

export const INITIAL_PASSWORD_CHANGE_FORM_STATE = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
};
