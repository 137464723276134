import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { useCenterCodeList } from '@src/Apis/useCenterCodeList';
import { DatePickerLabel } from '@src/Components/Date/DatePicker';
import Flex from '@src/Components/Flex';
import Check from '@src/Components/Forms/Check';
import { InputButtonLabel } from '@src/Components/Forms/InputButton';
import { SelectLabel } from '@src/Components/Forms/Select';
import { TextAreaLabel } from '@src/Components/Forms/TextArea';
import CenterCodeModal from '@src/Components/Modals/CenterCodeModal';
import { FormFieldAttributes, useDialog } from '@src/Hooks';
import FormField from '@src/Layout/components/FormField';

import {
  INITIAL_SHELF_LIFE_LIST_FILTER,
  SHELF_LIFE_로케이션타입,
  SHELF_LIFE_보관온도,
  SHELF_LIFE_보관유형,
  SHELF_LIFE_재고유형,
} from '../constant';

type ShelfLifeFilterParams = typeof INITIAL_SHELF_LIFE_LIST_FILTER;
type TargetFormName = keyof FormFieldAttributes<keyof ShelfLifeFilterParams>;

interface ShelfLifeFilterProps {
  formField: {
    form: ShelfLifeFilterParams;
    setForm: Dispatch<SetStateAction<ShelfLifeFilterParams>>;
    resetForm: VoidFunction;
    attributes: FormFieldAttributes<keyof ShelfLifeFilterParams>;
  };
}

function ShelfLifeFilter({ formField }: ShelfLifeFilterProps) {
  const { form, attributes } = formField;
  const centerCodeModal = useDialog('CenterCodeModal');
  const { data: centerCodeList } = useCenterCodeList();

  const getFormFieldKey = (name: string) => {
    const targetFormField = attributes?.[name as TargetFormName];
    const otherSideTargetName =
      name === 'imminentStockTarget' ? 'noShippingTarget' : 'imminentStockTarget';

    return { targetFormField, otherSideTargetName };
  };

  const onCheckTargetInquire = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const targetFormName = name as TargetFormName;

    const targetCheck = formField.form[targetFormName];
    const { targetFormField, otherSideTargetName } = getFormFieldKey(name);

    attributes.imminentStockTarget.onChange;

    targetFormField?.onChange({
      target: {
        value: '',
        name: otherSideTargetName,
      },
    });

    formField.attributes[targetFormName]?.onChange({
      target: { value: targetCheck !== 'checked' ? 'checked' : '', name },
    });
  };

  const openCenterCodeModal = () => {
    centerCodeModal.openDialog({
      title: '센터코드 조회',
      content: (
        <CenterCodeModal
          selectCenterCode={(rowData) => {
            const centerId = String(
              centerCodeList?.find((item) => item.centerCode === rowData.centerCode)?.id || ''
            );
            formField.setForm((prevForm) => ({
              ...prevForm,
              centerCode: rowData.centerCode as string,
              centerId,
            }));
          }}
        />
      ),
    });
  };

  return (
    <FormField>
      <Flex direction='column' gap={10}>
        <InputButtonLabel
          {...attributes.centerCode}
          label='센터코드'
          readOnly
          buttonClick={openCenterCodeModal}
        />
        <DatePickerLabel
          selected={form.dateOfRecord}
          label='조회기준일'
          maxDate={new Date()}
          {...attributes.dateOfRecord}
        />
        <SelectLabel
          label='재고유형'
          options={SHELF_LIFE_재고유형}
          {...attributes.productStockType}
        />
      </Flex>
      <Flex direction='column' gap={10}>
        <SelectLabel label='보관유형' options={SHELF_LIFE_보관유형} {...attributes.storageType} />
        <SelectLabel label='보관온도' options={SHELF_LIFE_보관온도} {...attributes.temperature} />
        <SelectLabel
          label='로케이션 타입'
          options={SHELF_LIFE_로케이션타입}
          {...attributes.locationType}
        />
      </Flex>
      <TextAreaLabel label='품목코드' {...attributes.productItemIds} />
      <TextAreaLabel label='품목바코드' {...attributes.productItemBarcodes} />

      <Flex direction='column' colGap={10}>
        <Check
          id='imminentStockTarget'
          name='imminentStockTarget'
          label='임박전환 대상 조회'
          checked={formField.form.imminentStockTarget === 'checked'}
          onChange={onCheckTargetInquire}
        />
        <Check
          id='noShippingTarget'
          name='noShippingTarget'
          label='출고불가 대상 조회'
          checked={formField.form.noShippingTarget === 'checked'}
          onChange={onCheckTargetInquire}
        />
      </Flex>
    </FormField>
  );
}

export default ShelfLifeFilter;
