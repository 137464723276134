// NAMING 규칙: 뒤에 REGEX를 붙여주세요.

export const PASSWORD_REGEX =
  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[.)(`~=+,><\-/?;:'"_!@#$%^&*])[a-zA-Z0-9.)(`~=+,><\-/?;:'"_!@#$%^&*]{8,20}$/;

export const PHONE_NUMBER_REGEX = /^010-[0-9]{4}-[0-9]{4}$/;

export const TEL_NUMBER_REGEX = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}$/;

export const EMAIL_REGEX =
  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

export const LAT_AND_LONG_REGEX = /^[0-9]+\.?[0-9]+$/;

export const ZIP_CODE_REGEX = /^\d{5}$/;

export const EMPTY_STRING_REGEX = /\s+/g;
