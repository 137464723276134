import DataSheetGrid, { mappingAllCheck } from '@Components/DataSheetGrid';
import Flex from '@Components/Flex';
import Button from '@Components/Forms/Button';
import { toastify } from '@Components/Toast';
import { useDialog, useFormField, useFullScreenActivityIndicator, useGridValue } from '@Hooks';
import FilterHeader from '@Layout/components/FilterHeader';
import ResultHeader from '@Layout/components/ResultHeader';
import { TEL_NUMBER_REGEX } from '@src/Constants/regex';
import { useMutation } from '@tanstack/react-query';
import { cropDecimalPoint, performIfConfirmed, performIfNotEmpty } from '@Utils/helper';

import { CenterManageFilter } from './components/CenterManageFilter';
import CenterManageModal from './components/CenterManageModal';
import { postCenterManageData, putCenterData, putCenterStatus } from './apis';
import { CenterManageRow } from './CenterManage.type';
import { CenterManageStatusCell, CenterManageTypeCell } from './CenterManageCell';
import { CENTER_MANAGE_GRID_OPTIONS } from './constant';

function CenterManage() {
  const { columns } = CENTER_MANAGE_GRID_OPTIONS;
  const { openDialog } = useDialog('CenterManageModal');
  const { showActivityIndicator, hideActivityIndicator } = useFullScreenActivityIndicator();

  const { attributes, form, resetForm } = useFormField({
    status: '',
    centerId: '',
  });

  const {
    gridList: centerList,
    setGridList: setCenterList,
    updatedRow: centerListUpdatedRow,
    setUpdatedRow: setCenterListUpdatedRow,
    rowChangeHandler: centerListRowChangeHandler,
  } = useGridValue<CenterManageRow>([], 'id');

  const targetRows = centerList.filter((it) => it.active);

  const onSuccess = () => search();

  const { mutate: postCenterData } = useMutation(postCenterManageData, {
    onSuccess: (data) => {
      data && setCenterList(data.map((it) => ({ ...it, zipcode: cropDecimalPoint(it.zipcode) })));
    },
    onSettled() {
      hideActivityIndicator();
    },
  });
  const { mutate: setDisabled } = useMutation(putCenterStatus, {
    onSuccess: () => {
      toastify('비활성화 되었습니다.');
      onSuccess();
    },
  });
  const { mutate: setAbled } = useMutation(putCenterStatus, {
    onSuccess: () => {
      toastify('활성화 되었습니다.');
      onSuccess();
    },
  });
  const { mutate: setUpdateData } = useMutation(putCenterData, {
    onSuccess: () => {
      toastify('저장 되었습니다.');
      onSuccess();
    },
  });

  const search = () => {
    const { centerId, status } = form;
    let params = {};
    if (!centerId && status) params = { status };
    if (centerId && !status) params = { centerId };
    if (centerId && status) params = { ...form };
    showActivityIndicator();
    postCenterData(params);
    setCenterListUpdatedRow([]);
  };

  const setStatus = (type: 'abled' | 'disabled') => {
    const isDisabled = type === 'disabled';

    if (!targetRows.length) {
      toastify('선택된 항목이 없습니다.', { type: 'error' });
      return false;
    }

    for (const row of targetRows) {
      if (isDisabled && row.activated === '비활성화') {
        toastify('이미 비활성화 되어있습니다.', { type: 'error' });
        return false;
      }

      if (!isDisabled && row.activated === '활성화') {
        toastify('이미 활성화되어 있습니다.', { type: 'error' });
        return false;
      }
    }

    const params = {
      ids: targetRows.map((value) => value.id),
      isDisabled,
    };

    if (isDisabled) {
      performIfConfirmed('비활성화 상태로 변경하시겠습니까?', () => {
        setDisabled(params);
      });
    } else {
      performIfConfirmed('활성화 상태로 변경하시겠습니까?', () => {
        setAbled(params);
      });
    }
  };

  const openNewModal = () => {
    openDialog({
      title: '신규',
      content: <CenterManageModal onSuccess={onSuccess} />,
      size: 'medium',
    });
  };

  const validation = (centerListUpdatedRow: CenterManageRow[]) => {
    for (const item of centerListUpdatedRow) {
      if (!item.name) {
        toastify('[센터명]을 입력해주세요.', { type: 'error' });
        return false;
      }

      if (item.name.length > 50) {
        toastify('[센터명]은 50자를 초과할 수 없습니다.', { type: 'error' });
        return false;
      }

      if (item.zipcode && (Number.isNaN(Number(item.zipcode)) || item.zipcode.length > 5)) {
        toastify('[우편번호]는 숫자 5자 형식으로 입력해주세요.', { type: 'error' });
        return false;
      }

      if (item.latitude && Number.isNaN(Number(item.latitude))) {
        toastify('[위도]는 숫자 형식으로 입력해주세요.', { type: 'error' });
        return false;
      }

      if (item.longitude && Number.isNaN(Number(item.longitude))) {
        toastify('[경도]는 숫자 형식으로 입력해주세요.', { type: 'error' });
        return false;
      }

      if (item.mainAddress && item.mainAddress.length > 100) {
        toastify('[주소]는 100자를 초과할 수 없습니다.', { type: 'error' });
        return false;
      }

      if (item.subAddress && item.subAddress.length > 60) {
        toastify('[상세주소]는 60자를 초과할 수 없습니다.', { type: 'error' });
        return false;
      }

      if (item.contact && !TEL_NUMBER_REGEX.test(item.contact)) {
        toastify(
          '[대표연락처] 올바른 전화번호 번호 형식이 아닙니다. 02-1234-1234와 같은 형식으로 입력해주세요.',
          { type: 'error' }
        );
        return false;
      }
    }

    return true;
  };

  const save = () => {
    if (!centerListUpdatedRow.length) {
      toastify('수정된 내역이 없습니다.', { type: 'error' });
      return false;
    }

    if (validation(centerListUpdatedRow) && window.confirm('모든 변경사항을 저장하시겠습니까?')) {
      const params = centerListUpdatedRow.map((it) => {
        return {
          id: it.id,
          name: it.name,
          address: {
            latitude: it.latitude ?? null,
            longitude: it.longitude ?? null,
            mainAddress: it.mainAddress ?? null,
            subAddress: it.subAddress ?? null,
            zipcode: it.zipcode ?? null,
          },
          contact: it.contact ?? null,
        };
      });
      setUpdateData({ listDto: params });
      resetForm();
    }
  };

  return (
    <div>
      <FilterHeader>
        <Button isPermissionRequired={false} onClick={search}>
          조회
        </Button>
        <Button onClick={openNewModal}>신규</Button>
        <Button onClick={save}>저장</Button>
        <Button onClick={performIfNotEmpty(targetRows, () => setStatus('abled'))}>활성화</Button>
        <Button onClick={performIfNotEmpty(targetRows, () => setStatus('disabled'))}>
          비활성화
        </Button>
      </FilterHeader>

      <CenterManageFilter attributes={attributes} />

      <Flex direction='column' rowGap={10}>
        <ResultHeader title='센터내역' total={centerList.length} />
        <DataSheetGrid
          {...mappingAllCheck(columns, centerList, setCenterList)}
          value={centerList}
          onChange={centerListRowChangeHandler}
          customRendererList={[
            { id: 'activated', component: CenterManageStatusCell },
            { id: 'type', component: CenterManageTypeCell },
          ]}
        />
      </Flex>
    </div>
  );
}

export default CenterManage;
