import { useState } from 'react';

import useRequest from '@src/Hooks/useRequest';
import { Nullable } from '@src/Utils/type';
import { useQueryClient } from '@tanstack/react-query';

export interface UploadedOutbound {
  centerCode: Nullable<string>;
  outboundCategoryValue: Nullable<string>;
  deliveryTypeValue: Nullable<string>;
  orderId: Nullable<number>;
  availableDay: Nullable<string>;
  arrivalCenterCode: Nullable<string>;
  vendorId: Nullable<number>;
  recipient: Nullable<string>;
  zipcode: Nullable<string>;
  address: Nullable<string>;
  detailAddress: Nullable<string>;
  mainMobileNo: Nullable<string>;
  subMobileNo: Nullable<string>;
  message: Nullable<string>;
  enterMethodValue: Nullable<string>;
  outboundRemark: Nullable<string>;
  productItemId: Nullable<number>;
  availableQuantity: Nullable<number>;
  outboundItemRemark: Nullable<string>;
  errorMessage: Nullable<string>;
  productItemName: Nullable<string>;
  location: string;
}

export const useOutboundRegisterListQuery = () => {
  const [formData, setFormData] = useState<FormData | null>(null);
  const [enabled, setEnabled] = useState(false);
  const queryClient = useQueryClient();

  const appendFile = (file: File) => {
    setEnabled(true);
    const formData = new FormData();
    formData.append('file', file);
    setFormData(formData);
  };

  const query = useRequest<UploadedOutbound[]>(
    {
      url: '/outbounds/excel-upload',
      method: 'POST',
      data: formData,
    },
    {
      enabled,
      onSettled() {
        setEnabled(false);
      },
      onError() {
        queryClient.setQueryData(['outbounds', 'excel-upload', formData], []);
      },
    }
  );

  return { query, appendFile };
};

export const useValidatedOutboundRegisterListQuery = (
  uploadedOutboundList: UploadedOutbound[] | undefined
) => {
  const [isPass, setIsPass] = useState(false);

  const query = useRequest<UploadedOutbound[]>(
    { url: '/outbounds/validation', method: 'POST', data: uploadedOutboundList },
    {
      enabled: false,
      cacheTime: 0,
      onSuccess: (data) => {
        setIsPass(data.every(({ errorMessage }) => errorMessage?.includes('성공')));
      },
    }
  );

  return { query, setIsPass, isPass };
};
