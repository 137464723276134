import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
    ${normalize}
    
    @font-face {
    font-family: 'Apple SD Gothic Neo';
    font-weight: 100;
    font-style: normal;
    font-display: swap;
    src: url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-Thin.woff2')
        format('woff2'),
        url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-Thin.ttf')
            format('truetype');
    }
    @font-face {
        font-family: 'Apple SD Gothic Neo';
        font-weight: 200;
        font-style: normal;
        font-display: swap;
        src: url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-ExtraLight.woff2')
            format('woff2'),
        url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-ExtraLight.ttf')
            format('truetype');
    }
    @font-face {
        font-family: 'Apple SD Gothic Neo';
        font-weight: 300;
        font-style: normal;
        font-display: swap;
        src: url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-Light.woff2')
            format('woff2'),
        url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-Light.ttf')
            format('truetype');
    }
    @font-face {
        font-family: 'Apple SD Gothic Neo';
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        src: url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-Regular.woff2')
            format('woff2'),
        url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-Regular.ttf')
            format('truetype');
    }
    @font-face {
        font-family: 'Apple SD Gothic Neo';
        font-weight: 500;
        font-style: normal;
        font-display: swap;
        src: url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-Medium.woff2')
            format('woff2'),
        url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-Medium.ttf')
            format('truetype');
    }
    @font-face {
        font-family: 'Apple SD Gothic Neo';
        font-weight: 600;
        font-style: normal;
        font-display: swap;
        src: url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-SemiBold.woff2')
            format('woff2'),
        url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-SemiBold.ttf')
            format('truetype');
    }
    @font-face {
        font-family: 'Apple SD Gothic Neo';
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        src: url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-Bold.woff2')
            format('woff2'),
        url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-Bold.ttf')
            format('truetype');
    }
    @font-face {
        font-family: 'Apple SD Gothic Neo';
        font-weight: 800;
        font-style: normal;
        font-display: swap;
        src: url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-ExtraBold.woff2')
            format('woff2'),
        url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-ExtraBold.ttf')
            format('truetype');
    }
    @font-face {
        font-family: 'Apple SD Gothic Neo';
        font-weight: 900;
        font-style: normal;
        font-display: swap;
        src: url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-Black.woff2')
            format('woff2'),
        url('https://cdn.jsdelivr.net/gh/fonts-archive/AppleSDGothicNeo/AppleSDGothicNeo-Black.ttf')
            format('truetype');
    }
    *, *::before, *::after{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: Apple SD Gothic Neo, sans-serif;
    }

    html{
        font-size: 62.5%;
    }

    body{
        font-size: 1.6rem;
        background-color: #fcfcfc;
    }

    button, a, label {
        cursor: pointer;
    }

    button:disabled{
        cursor:not-allowed;
    }

    button, input, textarea, select {
        background-color: #fff;
        border: 0;
        color:inherit;

        &:focus {
            outline: none;
        }
    }

    button {
        background-color: transparent;
    }

    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ul, li {
        list-style: none;
    }

    .react-datepicker__portal {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .width-100 {
        width: 100%;
    }

    .windows-os *::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #f5f5f5;
    }

    .windows-os *::-webkit-scrollbar {
        width: 12px;
        background-color: #f5f5f5;
    }

    .windows-os *::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color:  #8b95a1;
    }
`;

export default GlobalStyle;
