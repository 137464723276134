import dayjs from 'dayjs';
import { keyColumn, textColumn } from 'react-datasheet-grid';

import type { ShelfLifeStockExcelRow } from './ShelfLifeStockAddModal.type';

export const CAUTION_BOX_NEW_MESSAGES = [
  '센터코드 + 입고유형 + 운송유형 + 입고예정일자 + 거래처코드가 모두 같은 품목의 경우, 1개 입고 전표로 구분되어 등록됩니다.',
  '1개 입고 전표에는 같은 품목은 포함될 수 없습니다. 발주 어드민에서 증정품의 경우 같은 품목이 복수로 포함될 수 있지만, 입고 등록 시에는 해당 품목 수 합산해 등록됩니다.',
];

export const SHELF_LIFE_STOCK_ADD_GRID_OPTIONS = {
  columns: [
    {
      ...keyColumn('errorMessage', textColumn),
      title: '체크메시지',
      disabled: true,
      minWidth: 300,
    },
    {
      ...keyColumn('productItemId', textColumn),
      title: '품목코드',
      disabled: true,
    },
    {
      ...keyColumn('storageTypeValue', textColumn),
      title: '보관유형',
      disabled: true,
      minWidth: 80,
    },
    {
      ...keyColumn('location', textColumn),
      title: '로케이션',
      disabled: true,
      minWidth: 160,
    },
    {
      ...keyColumn('locationTypeValue', textColumn),
      title: '로케이션 타입',
      disabled: true,
      minWidth: 100,
    },
    {
      ...keyColumn('quantity', textColumn),
      title: '수량',
      disabled: true,
      minWidth: 50,
    },
    {
      ...keyColumn('itemBarcode', textColumn),
      title: '품목바코드',
      disabled: true,
      minWidth: 140,
    },
    {
      ...keyColumn('shelfLifeDay', textColumn),
      title: '유통기한',
      disabled: true,
    },
  ],
};

export const CAUTION_TIP_TEXT_ARRAY = [
  '보관유형: 파렛트랙, 선반랙, 경량랙',
  '로케이션 타입: 피킹, 보관, 입고대기, 출고가능, 반품, 불량, 보류',
];

export const SHELF_LIFE_STOCK_ADD_COLUMNS_KEY = {
  품목코드: 'productItemId',
  보관유형: 'storageTypeValue',
  로케이션: 'location',
  '로케이션 타입': 'locationTypeValue',
  수량: 'quantity',
  품목바코드: 'itemBarcode',
  유통기한: 'shelfLifeDay',
};

export const EXCEL_CHECK_ERROR_MESSAGE = '엑셀 체크에 실패하였습니다. 개발자에게 문의바랍니다.';

export const EXCEL_UPLOAD_SUCCESS_MESSAGE = '완료되었습니다.';

export const INVALID_CENTER_ID = '센터코드 등록과정에 문제가 발생했습니다. 개발자에게 문의주세요.';

export const REQUIRED_CENTER_CODE = '센터코드가 없습니다. 센터코드를 등록해주세요.';

export const SHELF_LIFE_STOCK_EXCEL_SAMPLE_NAME = '업로드_유통기한별재고';

export const INVALID_DATE_OF_RECORD =
  '기준날짜 형식이 올바르지 않습니다. 날짜를 다시 확인해주세요.';

export const REQUIRED_DATE_OF_RECORD = '기준날짜가 비어있습니다. 기준날짜를 입력해주세요.';

export const SHELF_LIFE_SUCCESS_SERVER_MESSAGE = '성공';

export const STANDARD_CURRENT_DATE = dayjs().format('YYYY-MM-DD');

export const DATE_FORMAT_REGEX = /^\d{4}-\d{2}-\d{2}$/;

export const STANDARD_CENTER_ID = '';

export const EXCEL_DATA_FORMAT: ShelfLifeStockExcelRow = {
  productItemId: 0,
  storageTypeValue: '',
  location: '',
  locationTypeValue: '',
  quantity: 0,
  itemBarcode: '',
  shelfLifeDay: '',
};

export const SHELF_LIFE_STOCK_DATE_HEADER = ['유통기한'] as const;
