import { EXCEL_UPLOAD_SUCCESS_MESSAGE } from '@Features/ShelfLifeStock/components/ShelfLifeStockAddModal/constant';
import { client } from '@src/client';
import { toastify } from '@src/Components/Toast';
import { useDialog, useFullScreenActivityIndicator } from '@src/Hooks';
import { useMutation } from '@tanstack/react-query';

import type { PostShelfLifeExcelStockBody } from './type';

interface UseShelfLifeExcelStockMutationProps {
  refetch: () => void;
}

const postShelfLifeExcelStock = async (body: PostShelfLifeExcelStockBody) => {
  const { data } = await client.post('/shelf-life/stocks', body);
  return data;
};
/**
 * 유통기한별 재고 엑셀등록시 엑셀데이터 DB등록훅
 */
export const useShelfLifeExcelStockMutation = ({
  refetch,
}: UseShelfLifeExcelStockMutationProps) => {
  const { showActivityIndicator, hideActivityIndicator } = useFullScreenActivityIndicator();
  const { resetDialog } = useDialog('ShelfLifeStockAddModal');

  const { mutate: saveShelfLifeExcelStock } = useMutation(postShelfLifeExcelStock, {
    onMutate: () => showActivityIndicator(),
    onSuccess: () => {
      refetch();
      resetDialog();
      toastify(EXCEL_UPLOAD_SUCCESS_MESSAGE);
    },
    onSettled: () => hideActivityIndicator(),
  });

  return { saveShelfLifeExcelStock };
};
